import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';
import { useMainCategoriesStore } from '../../model';

export const MainCategoriesLinks = () => {
  const { t } = useTranslation();
  const categories = useMainCategoriesStore((state) => state.mainCategories);

  return (
    <div className={styles.catalog}>
      <h3 className={styles.subtitle}>{t('catalog')}</h3>

      {categories.map((category) => (
        <Link
          className={styles.footer_link}
          to={'/catalog/' + category?.id}
          key={category?.id}
        >
          {category?.title}
        </Link>
      ))}
    </div>
  );
};
