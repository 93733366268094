import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";

import { useOutside } from "Components/Header/useOutsideClick";

import searchIcon from "./imgs/search.svg";
import closeIcon from "./imgs/close.svg";
import styles from "./index.module.scss";
import { useHeaderSearchStore } from "./model";
import { Loader } from "shared/ui/loader";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const Search = React.forwardRef(({ setSearchWrap, searchWrap }, ref) => {
  const { t } = useTranslation();

  const [cursor, setCursor] = useState(0);
  const searchListRef = useRef(null);
  const searchTargetRef = useRef(null);
  const searchInputRef = useRef(null);
  const [
    searchValue,
    searchResults,
    isSearchLoading,
    handleSearch,
    resetState,
    searchHistoryItems,
    getSearchHistoryItemsFromLS,
    setSearchedItemToLS,
    cleanSearch,
  ] = useHeaderSearchStore(
    (s) => [
      s.searchValue,
      s.searchResults,
      s.isSearchLoading,
      s.handleSearch,
      s.reset,
      s.searchHistoryItems,
      s.getSearchHistoryItemsFromLS,
      s.setSearchedItemToLS,
      s.cleanSearch,
    ],
    shallow
  );
  const searchList = [...searchResults?.categories, ...searchResults?.products];
  const navigate = useNavigate();

  useOutside(ref, searchTargetRef, () => setSearchWrap(false));

  useEffect(() => {
    if (searchWrap) {
      searchInputRef.current.focus();
    }
  }, [searchWrap]);

  useEffect(() => {
    getSearchHistoryItemsFromLS();
  }, [getSearchHistoryItemsFromLS]);

  function handleKeyDown(e) {
    scrollIntoItem();

    const list = searchList?.length > 0 ? searchList : searchHistoryItems;
    if (e.keyCode === 38 && cursor > 0) {
      setCursor((prevCursor) => prevCursor - 1);
    } else if (e.keyCode === 40 && cursor < list.length - 1) {
      setCursor((prevCursor) => prevCursor + 1);
    } else if (e.keyCode === 13) {
      if (list.length > 0) {
        const item = list?.[cursor];
        if (item) {
          onItemClick({
            item,
            type: item.type,
          });
        }
      }
    }
  }

  function scrollIntoItem() {
    const selected = searchListRef?.current?.querySelector(".activeSearchItem");
    if (selected) {
      selected?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }

  function onItemClick({ item, type }) {
    if (type === "category") {
      if (item.parent_id === null) {
        navigate(`/catalog/${item.id}`);
      } else {
        navigate(`/catalog/${item.parent_id}/${item.id}`);
      }
      setSearchedItemToLS({ item, type: "category" });
    } else if (type === "product") {
      navigate(`/itempage/${item.id}`);
      setSearchedItemToLS({ item, type: "product" });
    }
    setSearchWrap(false);
  }

  function onClose() {
    resetState();
    setSearchWrap(false);
  }
  function handleClean() {
    cleanSearch();
    searchInputRef.current.focus();
  }

  return (
    <div
      className={`${styles.wrapper} ${searchWrap ? styles.open_search : ""}`}
    >
      <div className={styles.searchWrapper} ref={searchTargetRef}>
        <div onClick={handleClean} className={styles.clean}>
          {t("clean")}
        </div>
        <div className={styles.search}>
          <div className={styles.search_box}>
            <img src={searchIcon} alt="searchIcon" />
            <input
              value={searchValue}
              onChange={async (evt) => {
                await handleSearch(evt.target.value);
                setCursor(0);
              }}
              onKeyDown={handleKeyDown}
              ref={searchInputRef}
              type="text"
              placeholder={t("enter_request")}
            />

            {isSearchLoading ? (
              <div className={styles.search_items}>
                <Loader loaderWrapperStyles={{ padding: 5 }} size={40} />
              </div>
            ) : searchResults?.categories?.length > 0 ||
              searchResults?.products?.length > 0 ? (
              <div className={styles.search_items} ref={searchListRef}>
                {searchList?.length > 0 &&
                  searchList.map((item, index) => (
                    <div
                      key={item?.id}
                      className={`${styles.search_item} ${
                        cursor === index ? "activeSearchItem" : ""
                      }`}
                      onClick={() =>
                        onItemClick({
                          item,
                          type: item.type,
                        })
                      }
                    >
                      <img
                        className={styles.search_img}
                        alt="img"
                        src={item?.img?.img}
                      />
                      <p className={styles.search_name}>{item?.title}</p>
                    </div>
                  ))}
              </div>
            ) : (
              searchHistoryItems.length > 0 && (
                <div className={styles.search_items}>
                  <p className={styles.historyTitle}>{t("history")}</p>
                  {searchHistoryItems?.map((item, index) => (
                    <div
                      key={item?.id}
                      className={`${styles.search_item} ${
                        cursor === index ? "activeSearchItem" : ""
                      }`}
                      onClick={() =>
                        item?.type === "category"
                          ? onItemClick({ item, type: "category" })
                          : onItemClick({ item, type: "product" })
                      }
                    >
                      <img
                        className={styles.search_img}
                        alt="img"
                        src={item?.img?.img}
                      />
                      <p className={styles.search_name}>{item?.title}</p>
                    </div>
                  ))}
                </div>
              )
            )}
          </div>
          <button onClick={onClose} className={styles.close_btn}>
            <img src={closeIcon} alt="closeIcon" />
          </button>
        </div>
      </div>
    </div>
  );
});

export default Search;
