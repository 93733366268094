import React, { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Header from "Components/Header";
import Footer from "Components/Footer";
import { useMainCategoriesStore } from "entities/main-categories";
import { Loader } from "shared/ui/loader";
import { useProductsStore } from "store/products";
import shallow from "zustand/shallow";

const Main = lazy(() => import("./pages/Main"));
const Catalog = lazy(() => import("./pages/Catalog"));
const About = lazy(() => import("./pages/About"));
const Contacts = lazy(() => import("./pages/Contacts"));
const ItemPage = lazy(() => import("./pages/ItemPage"));

function App() {
  const [active, setActive] = useState(false);
  const [searchWrap, setSearchWrap] = useState(false);
  const location = useLocation();

  const [changedUrl, setChangedUrl, resetSizeParams] = useProductsStore((s) => [
    s.changedUrl,
    s.setChangedUrl,
    s.resetSizeParams,
    ],
  shallow)

  // reset scroll and filterSlider on route change
  useEffect(() => {
    window.scrollTo(0, 0);
    if(!location.pathname.includes('/itempage') && location.pathname !== changedUrl) {
      setChangedUrl('')
      resetSizeParams();
    }
    // useProductsStore.getState().resetSizeParams();
  }, [location.pathname]);

  useEffect(() => {
    useMainCategoriesStore.getState().fetchMainCategories();
  }, []);

  return (
    <div className={`"App" ${active || searchWrap ? "app_flow" : ""}`}>
      <Suspense
        fallback={
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        }
      >
        <Header
          setSearchWrap={setSearchWrap}
          searchWrap={searchWrap}
          active={active}
          setActive={setActive}
        />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/catalog/:id" element={<Catalog />} />
          <Route path="/catalog/:id/:categoryId" element={<Catalog />} />
          <Route path="/about" element={<About />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/itempage/:id" element={<ItemPage />} />
        </Routes>
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
