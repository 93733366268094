import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as ArrowLeftIcon } from 'assets/imgs/swipe_arrow-left.svg';
import styles from './index.module.scss';
import './swiper.scss';

const breakpoints = {
  280: { slidesPerView: 1, spaceBetween: 6 },
  360: { slidesPerView: 1, spaceBetween: 6 },
  367: { slidesPerView: 1, spaceBetween: 6 },
  390: { slidesPerView: 1, spaceBetween: 6 },
  450: { slidesPerView: 1.4, spaceBetween: 6 },
  567: { slidesPerView: 1.8, spaceBetween: 6 },
  768: { slidesPerView: 2.3, spaceBetween: 6 },
  912: { slidesPerView: 2.6, spaceBetween: 6 },
  1024: { slidesPerView: 2.8, spaceBetween: 6 },
  1280: { slidesPerView: 3, spaceBetween: 6 },
  1440: { slidesPerView: 3.3, spaceBetween: 6 },
  1680: { slidesPerView: 3.8, spaceBetween: 6 },
  2048: { slidesPerView: 4.3, spaceBetween: 6 },
};

export function CategoriesSwiper({ categories, onCategoryClick }) {
  return (
    <div className={styles.categoriesWrapper}>
      <Swiper
        spaceBetween={16}
        grabCursor={true}
        slidesPerView={3.5}
        modules={[Navigation]}
        navigation={{
          prevEl: '.prev',
          nextEl: '.next',
        }}
        breakpoints={breakpoints}
      >
        {categories.map((category) => (
          <SwiperSlide key={category?.id}>
            <Category category={category} onCategoryClick={onCategoryClick} />
          </SwiperSlide>
        ))}
      </Swiper>

      <button className="prev">
        <ArrowLeftIcon className={styles.swiperPrevIcon} />
      </button>
      <button className="next">
        <ArrowLeftIcon className={styles.swiperNextIcon} />
      </button>
    </div>
  );
}

export function Category({ category, onCategoryClick }) {
  return (
    <div className={styles.categoryWrapper}>
      <div
        className={styles.category_box}
        onClick={() => onCategoryClick(category)}
      >
        <img
          className={styles.boxImg}
          src={category?.img?.img}
          alt="category"
        />
        <p key={category?.id} className={styles.categoty_text}>
          {category?.title}
        </p>
      </div>
    </div>
  );
}
