import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import logo from "../Header/img/logo.svg";
import facebook from "./imgs/facebook.svg";
import instagram from "./imgs/instagram.svg";
import telegram from "./imgs/telegram.svg";
import { useTranslation } from "react-i18next";
import { setting } from "../../apiex/baltichome";
import { MainCategoriesLinks } from "entities/main-categories";

const socialsDefault = [
  {
    icon: facebook,
    link: "",
  },
  {
    icon: instagram,
    link: "",
  },
  {
    icon: telegram,
    link: "",
  },
];

function Footer() {
  const [settings, setSettings] = useState({});
  const [socials, setSocials] = useState(socialsDefault);

  useEffect(() => {
    (async () => {
      const response = await setting({});
      setSettings(response);
      setSocials([
        { icon: facebook, link: response.facebook },
        { icon: instagram, link: response.instagram },
        { icon: telegram, link: response.telegram },
      ]);
    })();
  }, []);

  const { t } = useTranslation();

  return (
    <div className={`${styles.wrapper} pdfprint`}>
      <div className="container">
        <div className={styles.footer_inner}>
          <div className={styles.logo_box}>
            <Link className={styles.logo_box} to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className={styles.contacts}>
            <a href={"tel:" + settings.telephone} className={styles.phone_num}>
              {settings.telephone}
            </a>
            <a href={"mailto:" + settings.email} className={styles.mail}>
              {settings.email}
            </a>
            <div className={styles.socials}>
              {socials.map((el, i) => (
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={el.link}
                  className={styles.socials_link}
                  key={i}
                >
                  <img src={el.icon} alt="socials" />
                </a>
              ))}
            </div>
          </div>
          <div className={styles.list_box}>
            <div className={styles.company}>
              <h3 className={styles.subtitle}>{t("company")}</h3>
              <Link className={styles.footer_link} to={"catalog"}>
                {t("furniture")}
              </Link>
              <Link className={styles.footer_link} to={"about"}>
                {t("about_us")}
              </Link>
              <Link className={styles.footer_link} to={"contacts"}>
                {t("contacts")}
              </Link>
            </div>

            <MainCategoriesLinks />
            {/* <div className={styles.catalog}>
                            <h3 className={styles.subtitle}>{t('catalog')}</h3>
                            <Link className={styles.footer_link} to={''}>
                                {t('sofas')}
                            </Link>
                            <Link className={styles.footer_link} to={''}>
                                {t('armchairs')}
                            </Link>
                            <Link className={styles.footer_link} to={''}>
                                {t('accessories')}
                            </Link>
                            <Link className={styles.footer_link} to={''}>
                                {t('pufiks')}
                            </Link>
                            <Link className={styles.footer_link} to={''}>
                                {t('couches')}
                            </Link>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
