import create from 'zustand';
import { debounce } from 'shared/lib/debounce';
import { fetchProductSearch } from 'apiex/baltichome';

const searchResultsInitial = { products: [], categories: [] };


const initialState = {
  searchValue: '',
  searchResults: searchResultsInitial,
  isSearchLoading: false,
  searchHistoryItems: [],
};

export const useHeaderSearchStore = create((set, get) => ({
  ...initialState,

  debouncedSearch: debounce(async (searchText) => {
    const trimmedText = searchText.trim();

    set({ searchResults: searchResultsInitial });
    if (trimmedText.length > 0) {
      set({ isSearchLoading: true });
      try {
        const searchResults = await fetchProductSearch({
          search: trimmedText,
        });
        const mappedSearchResults = {
          products: searchResults.products.map((product) => ({
            ...product,
            type: 'product',
          })),
          categories: searchResults.categories.map((category) => ({
            ...category,
            type: 'category',
          })),
        };
        set({ searchResults: mappedSearchResults });
      } catch (error) {
        return;
      } finally {
        set({ isSearchLoading: false });
      }
    }
  }, 500),

  async handleSearch(searchText) {
    set({ searchValue: searchText });
    await get().debouncedSearch(searchText);
  },

  cleanSearch() {
    set({ searchValue: '', searchResults: searchResultsInitial });
  },

  setSearchedItemToLS({ item, type }) {
    const { searchHistoryItems } = get();
    const searchedItem = { ...item, type };

    const newSearchHistoryItems = [searchedItem, ...searchHistoryItems].filter(
      (item, index, self) => {
        return index === self.findIndex((t) => t.id === item.id) && index < 6;
      }
    );

    set({ searchHistoryItems: newSearchHistoryItems });
    localStorage.setItem(
      'searchHistoryItems',
      JSON.stringify(newSearchHistoryItems)
    );
  },

  getSearchHistoryItemsFromLS() {
    const hours = 24;

    const now = new Date().getTime();
    const searchHistoryExpireTime = localStorage.getItem(
      'searchHistoryExpireTime'
    );
    if (searchHistoryExpireTime === null) {
      localStorage.setItem('searchHistoryExpireTime', now);
    } else {
      if (now - searchHistoryExpireTime > hours * 60 * 60 * 1000) {
        localStorage.setItem('searchHistoryItems', null);
        localStorage.setItem('searchHistoryExpireTime', now);
      }
    }

    const searchHistoryItems = JSON.parse(
      localStorage.getItem('searchHistoryItems')
    );
    if (searchHistoryItems) {
      set({ searchHistoryItems });
    }
  },

  reset() {
    set(initialState);
  },
}));
