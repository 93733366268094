import { useEffect } from "react";

export const useOutside = (triggerRef, ref, cb) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      const isOutside =
        triggerRef?.current &&
        ref?.current &&
        !triggerRef?.current.contains(e.target) &&
        !ref?.current.contains(e.target);
      if (isOutside) {
        cb();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.addEventListener("click", handleClickOutside);
    };
  }, [ref, triggerRef, cb]);
};
