import React from 'react';
import MediaQuery from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.scss';
import { useMainCategoriesStore } from '../../model';
import { CategoriesSwiper, Category } from '../categories-swiper';

export const SwipingMainCategoties = () => {
  const categories = useMainCategoriesStore((state) => state.mainCategories);
  const navigate = useNavigate();

  function onCategoryClick(category) {
    navigate('/catalog/' + category?.id);
  }

  return (
    <div className={`${styles.wrapper} pdfprint`}>
      <div className="container">
        <MediaQuery maxWidth={576}>
          {(matches) =>
            matches ? (
              <div className={styles.wrapper}>
                {categories.map((el, i) => (
                  <Category
                    key={i}
                    category={el}
                    onCategoryClick={onCategoryClick}
                  />
                ))}
              </div>
            ) : (
              <CategoriesSwiper
                categories={categories}
                onCategoryClick={onCategoryClick}
              />
            )
          }
        </MediaQuery>
      </div>
    </div>
  );
};
