import styles from "./style.module.scss";

/* 
    Loader props:
    {
        size?: string | number, // (width, height)
        loaderWrapperStyles?: CSSProperties,
        loaderStyles?: CSSProperties
        variant?: 'thin'
    }
*/

export const Loader = ({
  loaderWrapperStyles,
  size,
  loaderStyles,
  variant,
}) => {
  return (
    <div className={styles.loaderWrapper} style={loaderWrapperStyles}>
      <div
        className={`${styles.loader} ${
          variant === "thin" ? styles.thinLoader : ""
        }`}
        style={{
          width: size ?? "60px",
          height: size ?? "60px",
          ...loaderStyles,
        }}
      />
    </div>
  );
};
