import { category } from "apiex/baltichome";
import create from "zustand";

const initialState = {
  mainCategories: [],
};

export const useMainCategoriesStore = create((set, get) => ({
  ...initialState,

  async fetchMainCategories() {
    const { mainCategories } = get();
    if (mainCategories.length) return;
    try {
      const mainCategories = await category();
      set({ mainCategories });
    } catch (err) {
      return;
    }
  },
}));
