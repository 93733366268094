import create from "zustand";
import {devtools} from "zustand/middleware"

const initialState = {
  material_ids: [],
  category_id: null,
};

export const initialSizeParams = {
  changed: true,
  min_width: 10,
  min_height: 10,
  min_length: 10,
  max_width: 50,
  max_height: 50,
  max_length: 50,
};

export const initialDimension = {
  length: {
    min: 0,
    max: 900,
  },
  width: {
    min: 0,
    max: 900,
  },
  height: {
    min: 0,
    max: 900,
  },
};

export const initialAppliedSizeParams = {
  offset: null,
};

export const useProductsStore = create(devtools((set, get) => ({
  ...initialState,
  productsData: [],
  limit: 16,
  lang: "ru",
  offset: 0,
  category_id: null,
  changedUrl: '',
  material_ids: [],
  pdfProducts: [],
  dimensions: initialDimension,
  search: "",
  sizeParams: initialSizeParams,
  appliedSizeParams: initialAppliedSizeParams,
  onSizeParamsChange: (params) => {
    const { changed, ...args } = get().sizeParams;
    set({ sizeParams: { ...args, ...params } });
  },

  resetSizeParams() {
    set({ sizeParams: initialSizeParams, dimensions: initialDimension, appliedSizeParams: initialAppliedSizeParams });
  },

  onDimensionsChange: (params) => {
    const { changed, ...args } = get().dimensions;
    set({ dimensions: { ...args, ...params } });
  },
  onApplySizeParamsClick(paylod = {}) {
    const { sizeParams, appliedSizeParams } = get();
    const concatData = Object.assign(appliedSizeParams, sizeParams)
    set({ appliedSizeParams: { ...concatData, ...paylod } });
  },

  setChangedUrl(payload) {
    set({ changedUrl: payload });
  },

  setOffset(payload) {
    const { appliedSizeParams } = get();
    set({ appliedSizeParams: { ...appliedSizeParams, offset: payload } });
  },

  addOffsetStep(payload = 16) {
    const { appliedSizeParams } = get();
    set({ appliedSizeParams: { ...appliedSizeParams, offset: Number(appliedSizeParams.offset) + payload } });
  },

  onResetSizeParamsClick: (params) => {
    console.log(params);
    const { dimensions } = get();
    const defaultValue = {
      min_width: dimensions.width.min,
      max_width: dimensions.width.max,
      min_height: dimensions.height.min,
      max_height: dimensions.height.max,
      min_length: dimensions.length.min,
      max_length: dimensions.length.max,
    }
    set({
      sizeParams: defaultValue,
      appliedSizeParams: { offset: null, ...defaultValue },
    });
  },

  setProductData: (payload) => {
    set({ productsData: payload });
  },
  addProductData: (payload) => {
    if (payload !== []) {
      set({ productsData: [...get().productsData, ...payload] });
    }
  },
  setMaterialIds: (payload) => {
    set({ material_ids: payload });
  },
  setCategoryId: (payload) => {
    set({ category_id: payload });
  },
  incrementOffset: (payload = null) => {
    set({ offset: get().offset + payload ? payload : get().limit });
  },
  resetOffset: (payload = 0) => {
    set({ appliedSizeParams: { ...get().appliedSizeParams, offset: 0 } });
  },
  reset: (object) => {
    set(object ? object : initialState);
  },
  SetPdfProducts: (payload) => {
    set({ pdfProducts: payload });
  },
})));
